import styled from '@emotion/styled';
import PropTypes from 'prop-types';
import React from 'react';
import Icon from './icon';
import SocialIcons from './social-icons';
import { socialIconList } from './_config/social-icon-list';
import { mq } from './_shared/media';
import { StyledH1 } from './_shared/styled-headings';
import { StyledSection } from './_shared/styled-section';

const StyledContactSection = styled(StyledSection)`
  margin-bottom: 0px;
  @media (min-width: 960px) {
    margin-bottom: 70px;
  }
`;
const StyledContainer = styled.section`
  display: flex;
  align-items: center;

  & > svg {
    font-size: 2rem;
    margin-right: 0.75rem;
    width: 30px !important;
  }
`;
const StyledFormContainer = styled.section`
  & > span {
    font-size: 0.75rem;
    font-weight: 500;
  }
`;
const StyledForm = styled.div`
  color: var(--primary-color);

  font-weight: normal;
`;
const StyledSeparator = styled.div`
  height: 1px;
  width: 100%;
  background-color: var(--body-color);
`;
const StyledContacts = styled.div`
  width: 100%;
  margin-top: 2rem;
  margin-bottom: 1rem;
  display: flex;
  flex-direction: column;
  @media (min-width: 960px) {
    flex-direction: row;
  }
  ${mq.gt.xs} {
    grid-template-columns: repeat(2, 1fr);
    row-gap: 20px;
  }
  ${mq.gt.sm} {
    grid-template-columns: repeat(3, 1fr);
  }
`;

const StyledContactsGeneral = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 30px;

  & > * + * {
    margin-top: 20px;
  }
  @media (min-width: 450px) {
    justify-content: flex-start;
    flex-direction: row;
    flex: 2;
    & > * + * {
      margin-left: 30px;
      margin-top: 0px;
    }
  }

  @media (min-width: 900px) {
    margin-bottom: 0px;
  }
`;

const Contact = ({ data }) => {
  const email = data.nodes[0].Email;
  const phone = data.nodes[0].Phone;

  return (
    <React.Fragment>
      <StyledContactSection id="contact">
        <StyledH1>Contact Details</StyledH1>
        <StyledSeparator />
        <StyledContacts>
          <StyledContactsGeneral>
            {email && (
              <StyledContainer>
                <Icon icon="paper-plane" />
                <StyledFormContainer>
                  <StyledForm>My E-Mail</StyledForm>
                  <span>{email}</span>
                </StyledFormContainer>
              </StyledContainer>
            )}
            {phone && (
              <StyledContainer>
                <Icon icon="mobile-alt" />
                <StyledFormContainer>
                  <StyledForm>Phone Number</StyledForm>
                  <span>{phone}</span>
                </StyledFormContainer>
              </StyledContainer>
            )}
          </StyledContactsGeneral>
          <SocialIcons icons={socialIconList} inContact={true} />
        </StyledContacts>
      </StyledContactSection>
    </React.Fragment>
  );
};

Contact.propTypes = {
  data: PropTypes.object.isRequired,
};

export default Contact;
