import styled from '@emotion/styled';
import PropTypes from 'prop-types';
import React from 'react';
import TechList from './tech-list';
import { StyledH1, StyledH4 } from './_shared/styled-headings';
import { StyledSection } from './_shared/styled-section';

const StyledAboutContainer = styled.article`
  display: grid;
  grid-template-columns: repeat(1, 1fr);
  grid-gap: 2.5rem;
  padding: 1.5rem 0;
`;

const Skills = ({ data }) => {
  const Skills = data.nodes[0].Skills;

  return (
    <StyledSection id="skills">
      <StyledH1>Skills</StyledH1>
      <StyledAboutContainer>
        <div>
          {Skills.map((skill, index) => {
            return (
              <React.Fragment key={index}>
                <StyledH4>{skill.name}:</StyledH4>
                <TechList techs={skill.items} />
              </React.Fragment>
            );
          })}
        </div>
      </StyledAboutContainer>
    </StyledSection>
  );
};

Skills.propTypes = {
  data: PropTypes.object.isRequired,
};

export default Skills;
