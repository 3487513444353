import styled from '@emotion/styled';
import { GatsbyImage } from 'gatsby-plugin-image';
import PropTypes from 'prop-types';
import React from 'react';
import { mq } from './_shared/media';
import { StyledH1 } from './_shared/styled-headings';
import { StyledStaticImageContainer } from './_shared/styled-image-container';
import { StyledSection } from './_shared/styled-section';

const StyledAboutContainer = styled.article`
  display: grid;
  grid-template-columns: repeat(1, 1fr);
  grid-gap: 2.5rem;
  padding: 2.5rem 0;

  ${mq.gt.sm} {
    grid-template-columns: repeat(2, 1fr);
  }
`;
const StyledTextSection = styled.section`
  white-space: pre-line;
`;

const About = ({ data }) => {
  const PP = data.nodes[0].PP.localFile
  const Description = data.nodes[0].Description

  const image = PP ? PP.childImageSharp.gatsbyImageData : null;

  return (
    <StyledSection id="about">
      <StyledH1>About Me</StyledH1>
      <StyledAboutContainer>
        {image && (
          <StyledStaticImageContainer>
            <GatsbyImage image={image} alt="image of myself" objectFit="contain" />
          </StyledStaticImageContainer>
        )}
        <div>
          <StyledTextSection dangerouslySetInnerHTML={{ __html: Description }} />
        </div>
      </StyledAboutContainer>
    </StyledSection>
  );
};

About.propTypes = {
  data: PropTypes.object.isRequired,
};

export default About;
