import { graphql } from 'gatsby';
import styled from '@emotion/styled';
import PropTypes from 'prop-types';
import React from 'react';
import About from '../components/about';
import Contact from '../components/contact';
import FeaturedProjects from '../components/featured-projects';
import Hero from '../components/hero';
import Layout from '../components/layout';
// import RecentPosts from '../components/recent-posts';
import Skills from '../components/skills';
import SEO from '../components/seo';
import { indexMenuLinks } from '../components/_config/menu-links';
import { pageTransition } from '../animations';

const StyledPageTransitionPrimary = styled.div`
  position: fixed;
  left: 0;
  top: 0;
  min-height: 100vh;
  width: 100vw;
  background: #252839;
`;

const StyledPageTransitionBackground = styled.div`
  position: fixed;
  left: 0;
  top: 0;
  min-height: 100vh;
  width: 100vw;
  background: #f1b631;
`;

const Index = ({ data }) => {
  const primaryRef = React.useRef(null);
  const backgroundRef = React.useRef(null);

  React.useLayoutEffect(() => {
    pageTransition(primaryRef.current, backgroundRef.current);
  }, []);

  const heroData = {
    author: data.site.siteMetadata.author,
    tagline: data.hero.nodes[0].Subheading,
  };

  return (
    <Layout menuLinks={indexMenuLinks}>
      <SEO title="Home" />
      <Hero data={heroData} />
      <About data={data.about} />
      <Skills data={data.skills} />
      <FeaturedProjects featured={data.featuredProjects} />
      {/* <RecentPosts data={data.blog.edges} /> */}
      <Contact data={data.contact} />
      <StyledPageTransitionPrimary ref={primaryRef} />
      <StyledPageTransitionBackground ref={backgroundRef} />
    </Layout>
  );
};

Index.propTypes = {
  data: PropTypes.object.isRequired,
};

export default Index;

export const query = graphql`
  {
    site {
      siteMetadata {
        author
      }
    }

    hero: allStrapiProfiles(limit: 1) {
      nodes {
        Name
        Subheading
      }
    }

    about: allStrapiProfiles(limit: 1) {
      nodes {
        PP {
          localFile {
            childImageSharp {
              gatsbyImageData(layout: FULL_WIDTH)
            }
          }
        }
        Description
      }
    }

    skills: allStrapiProfiles {
      nodes {
        Skills {
          items
          name
        }
      }
    }

    featuredProjects: allStrapiProjects(filter: { Featured: { eq: true } }) {
      nodes {
        Desc
        Image {
          localFile {
            childImageSharp {
              gatsbyImageData(layout: FULL_WIDTH)
            }
          }
        }
        Name
        Repo
        Kaggle  
        Tags
        Technologies
      }
    }

    contact: allStrapiProfiles(limit: 1) {
      nodes {
        Email
        Phone
      }
    }
  }
`;
